import { CONTRACT_ABI } from './../utils/constants/tokens';
import { BrowserProvider, Contract, JsonRpcProvider } from 'ethers';
import { useWeb3ModalProvider } from '@web3modal/ethers/vue';
import type { CallAbiContract } from '~/types/Web3Provider';
import useEnvs from './useEnvs';

export default () => {
  const { walletProvider } = useWeb3ModalProvider();
  const { blockchain } = useEnvs();

  const getContract = async (contract: keyof typeof CONTRACT_ABI, address: string) => {
    if (walletProvider.value) {
      const provider = new BrowserProvider(walletProvider.value);
      const signer = await provider.getSigner();
      return new Contract(address, CONTRACT_ABI[contract], signer);
    }
  };

  const getContractReadOnly = async (contract: keyof typeof CONTRACT_ABI, address: string) => {
    const provider = new JsonRpcProvider(blockchain.nodeUrl);

    return new Contract(address, CONTRACT_ABI[contract], provider);
  };

  const getMethodObj = async ({ contract, methodName, methodArguments = [], address }: CallAbiContract) => {
    const contractObj = await getContract(contract, address);
    const method = contractObj?.getFunction(methodName);
    return method?.populateTransaction(...methodArguments);
  };

  const getMethodCall = async ({ contract, methodName, address }: CallAbiContract) => {
    const contractObj = await getContractReadOnly(contract, address);
    const method = contractObj?.getFunction(methodName);
    return method;
  };

  return {
    getContract,
    getMethodObj,
    getMethodCall,
    getContractReadOnly
  };
};
