<template>
  <div>
    <el-dialog
      v-model="isShowReferralPopup"
      class="referralModal"
      :class="{ 'ru-locale': $i18n.locale === 'ru' }"
      :title="$t('registerUserWelcomeToReferralProgram')"
      :append-to-body="true"
      :destroy-on-close="true"
      modal-class="modalCls"
      :width="$device.isMobile ? '80%' : '50%'"
      @closed="closeReferral"
    >
      <p>{{ $t('registerUserNowYouHaveTheUniqueOpportunity') }}</p>
      <p>
        {{ $t('registerUserGetYourPersonalLink') }}
        <br />
        <span>{{ refId && `${$t('registerUserYourReferrersId')}: ${refId}` }}</span>
        <br />
        <span>{{ $t('registerUserJoinUsAndTurnYourRecommendations') }}</span>
      </p>

      <div class="btns">
        <button
          :loading="joinLoading"
          class="btn-primary participate"
          size="large"
          :disabled="joinLoading"
          @click="registerUserWithReferral(Number(refId) || 0)"
        >
          <span class="button-content">
            <span>{{ !address ? $t('registerUserUseWalletToParticipate') : $t('registerUserParticipate') }}</span>
            <span class="right-arrow-icon" />
          </span>
        </button>
      </div>
    </el-dialog>
    <el-dialog
      v-model="isShowReferrerError"
      :title="$t('registerUserErrorInReferrerId')"
      :append-to-body="true"
      modal-class="modalCls"
      :class="{ 'ru-locale': $i18n.locale === 'ru' }"
      class="referralModal"
      :width="$device.isMobile ? '80%' : '50%'"
      :destroy-on-close="true"
      @closed="closeReferral"
    >
      <p>
        {{ $t('registerUserWeAreSorryButTheReferrerId') }}
        <span>{{ refId && `"${refId}" ` }}</span>
        {{ $t('registerUserInTheReferralLinkIsntValid') }}
      </p>
    </el-dialog>
    <el-dialog
      v-model="showCancelPopup"
      class="popup-canceled buy-popup"
      :class="{ 'ru-locale': $i18n.locale === 'ru' }"
      :fullscreen="$device.isMobile"
      :title="$t('referralPopupCanceledTitle')"
      :append-to-body="true"
    >
      <p>{{ $t(errorMessage) }}</p>

      <div class="btns-wrapper">
        <button class="btn-primary" size="large" @click="showCancelPopup = false">OK</button>
      </div>
    </el-dialog>
  </div>
</template>
<script setup lang="ts">
import { useWeb3Modal, useWeb3ModalAccount } from '@web3modal/ethers/vue';
import { BigNumber } from 'bignumber.js';
import { ZeroAddress } from 'ethers';
import { ref, watch } from 'vue';
import PearlApiService from '~/composables/PearlApiService';
import useEnvs from '~/composables/useEnvs';
import type { IUserModel, IUserSubscribers } from '~/types/apiService';
import { ACTION_REJECTED, apiUrls } from '~/utils/constants';

const { t } = useI18n();

const { address, isConnected } = useWeb3ModalAccount();
const store = useMainStore();
const { open } = useWeb3Modal();
const { registerInReferral } = useStakingMethods();
const { blockchain, apiUrl } = useEnvs();

const route = useRoute();
const router = useRouter();
const { isMobile } = useDevice();
const isShowReferralPopup = ref(false);
const isShowReferrerError = ref(false);
const showCancelPopup = ref(false);
const errorMessage = ref('');
const joinLoading = ref(false);
const buyConfNumber = ref(0);
const refId = useCookie('refId');

const { data: referrerData } = await useFetch<IUserModel['user']>(`${apiUrls.user.user}/${refId.value}`, {
  baseURL: apiUrl,
  watch: [refId],
  immediate: true,
  key: 'referrerData'
});

const { data: userData, status: userDataStatus } = useFetch<IUserModel>(apiUrls.v1.user, {
  baseURL: apiUrl,
  watch: [address],
  immediate: false,
  query: {
    address: address
  }
});

const closeReferral = () => {
  isShowReferralPopup.value = false;
  if (route.path.includes('referral')) {
    router.push('/');
  }
};

const { data: checkReferrerExists, status: checkReferrerExistsStatus } = useFetch<{ status: string; exists: boolean }>(
  apiUrls.user.exists,
  {
    query: {
      id: refId
    },
    key: 'checkReferrerExists',
    baseURL: apiUrl,
    watch: [refId, address]
  }
);
const registerHandler = async () => {
  if (!address.value) return;
  const getUserResp = await PearlApiService.getUser(apiUrl, address.value);
  if (getUserResp?.user?.id) {
    ElNotification.info({
      title: '',
      message: t('registerUserAlreadyJoined')
    });
    isShowReferralPopup.value = false;
    return;
  }

  try {
    if (address.value) {
      const receipt = await registerInReferral(referrerData.value?.address || ZeroAddress, () => {
        buyConfNumber.value += 1;
      });
      if (!receipt?.hash) return;

      saveHashToLocalStorage(t('notificationRegisterInReferral'), receipt.hash);
      store.updateVersion();

      await receipt?.wait(blockchain.minConfirmationsCount);

      const confirmations = await receipt.confirmations();
      if (confirmations >= blockchain.minConfirmationsCount && receipt?.hash) {
        modifyHistoryHashStatus(receipt.hash, 'Done');
        store.updateVersion();
      }

      const [userData, userSubscribersData] = await Promise.allSettled([
        PearlApiService.getUser(apiUrl, address.value),
        PearlApiService.getUserSubscribers(apiUrl, address.value)
      ]).then((results) => results.map((res) => (res.status === 'fulfilled' ? res.value : null)));

      if (userData) store.setUserData(userData as IUserModel);

      if (userSubscribersData) store.setUserSubscribersData(userSubscribersData as IUserSubscribers);
      joinLoading.value = false;
      isShowReferralPopup.value = false;
    }
  } catch (err) {
    showCancelPopup.value = true;
    if ((err as { code: string })?.code === ACTION_REJECTED) {
      errorMessage.value = 'referralInvitationCancelled';
      return;
    }
    errorMessage.value = stringifyError(err);
  } finally {
    joinLoading.value = false;
  }
};

const registerUserWithReferral = (ref?: number) => {
  // address or ref is undefined
  if (!isMobile && typeof ref !== 'number') {
    return;
  }
  joinLoading.value = true;

  // connect metamask first
  if (!address.value) {
    // if no address were found, trigger wallet logic;
    open();
    return;
  }
};

watch([address, joinLoading, isConnected], ([newAddress, newLoader, newIsConnected]) => {
  if (newAddress && newLoader && newIsConnected) registerHandler();
});

watch([() => route.query.ref, () => userData.value?.user], () => {
  if (route.query.ref && userData.value?.user) {
    ElNotification.info({
      title: '',
      message: t('registerUserAlreadyJoined')
    });
    return;
  }
});

watch([() => route.query.ref, () => userData.value?.user, userDataStatus], () => {
  if (
    route.query.ref &&
    BigNumber(route.query.ref.toString()).isNaN() &&
    !userData.value?.user &&
    userDataStatus.value !== 'pending' &&
    userDataStatus.value !== 'idle'
  ) {
    ElNotification.warning({
      title: '',
      message: t('registerUserInvalidReferrer')
    });
    return;
  }
});

watch(
  [
    () => checkReferrerExists.value?.exists,
    refId,
    checkReferrerExistsStatus,
    userDataStatus,
    address,
    () => route.name,
    () => route.query.ref,
    () => userData.value?.user
  ],
  async ([newExists]) => {
    if (
      ((route.query.ref && !BigNumber(route.query.ref.toString()).isNaN()) || route.name === 'referral') &&
      !newExists &&
      userDataStatus.value !== 'pending' &&
      userDataStatus.value !== 'idle' &&
      checkReferrerExistsStatus.value !== 'pending' &&
      checkReferrerExistsStatus.value !== 'idle' &&
      !userData.value?.user
    ) {
      isShowReferrerError.value = true;
      isShowReferralPopup.value = false;
    }

    if (
      (route.query.ref || route.name === 'referral') &&
      newExists &&
      userDataStatus.value !== 'pending' &&
      userDataStatus.value !== 'idle' &&
      !userData.value?.user
    ) {
      isShowReferralPopup.value = true;
    }
  },
  { immediate: true }
);
</script>

<style lang="scss" scoped>
.participate {
  margin-top: 20px;
}

.btn-primary:not(:disabled):hover {
  .right-arrow-icon {
    background-image: url('~/public/img/icons/login-arrow-hover.svg');
  }
}

.button-content {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 5px;
}

.right-arrow-icon {
  display: block;
  background-image: url('~/public/img/icons/login-arrow.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 12px;
  width: 15px;
  transition: background-image 0.3s ease;
}
</style>

<style lang="scss">
.referralModal {
  .el-dialog__body {
    color: #fff;
    text-align: center;
  }
}
</style>
